import { cloneDeep } from 'lodash';
import React, { useEffect, useMemo, useState } from 'react';
import Select from 'react-select';
import { getCLPInstallmentData } from 'redux/sales';
import { useAppDispatch, useAppSelector } from 'redux/store';

import CustomLinkInstallmentRow from './CustomLinkInstallmentRow';

const CustomLinkInstallments = props => {
  const dispatch = useAppDispatch();

  const { formikProps, project_id, extraCharges, OCList, EDIT } = props;
  const { values, setFieldValue } = formikProps;

  // redux state values
  const {
    CLPInstallmentList: installmentsList,
    CLPInstallmentsInformation: installmentsInformation,
  } = useAppSelector(s => s.sales);

  const [showInstall, setShowInstall] = useState(false);
  const [isUnlinked, setIsUnlinked] = useState(false);
  const [clpDataPer, setClpDataPer] = useState([]);
  const [selected, setSelected] = useState(false);

  // useEffect(() => {
  //   dispatch(getCLPInstallmentOptions({ project_id, tower_id: tower_id }));
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  // useEffect(() => {
  //   handleTotalPaymentCharge();
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [values.clp_installments]);

  useEffect(() => {
    mapPaymentInstallments();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [installmentsInformation]);

  const mapPaymentInstallments = () => {
    const schedules = installmentsInformation || [];

    const updatedData = schedules?.map(installment => ({
      clp_payment_no: installment?.id,
      clp_payment_installment: installment?.name,
      clp_per: Number(installment?.percentage),
      clp_basic_amt: 0,
      clp_otherchages_amt: 0,
      clp_extrachages_amt: 0,
      clp_installment_amount: 0,
      clp_gst: 0,
      clp_date: installment?.due_date,
    }));

    updatedData.push({
      clp_payment_no: (values?.installments?.length ?? 0) + 1,
      clp_payment_installment: 'Other Charges (Separately)',
      clp_otherchages_amt: 0,
      clp_extrachages_amt: 0,
      clp_installment_amount: 0,
      clp_gst: 0,
      lastRow: 'true',
    } as any);

    setClpDataPer(updatedData);
    setFieldValue('clp_installments', updatedData);
  };

  const addPaymentSchedule = () => {
    const updatedInstallments = cloneDeep(values.clp_installments);

    const newInstallment = {
      clp_payment_no: 0,
      clp_payment_installment: '',
      clp_due_date: '',
      clp_per: 0,
      clp_basic_amt: 0,
      clp_otherchages_amt: 0,
      clp_extrachages_amt: 0,
      clp_installment_amount: 0,
      clp_gst: 0,
      custom: true,
    };

    if (updatedInstallments.every(installment => !installment.custom)) {
      updatedInstallments.unshift(newInstallment);
    } else {
      // Find the last custom installment and add the new one after it
      const lastCustomInstallmentIndex = updatedInstallments
        .map((installment, index) => (installment.custom ? index : -1))
        .filter(index => index !== -1)
        .pop(); // Get the index of the last custom installment

      if (lastCustomInstallmentIndex !== undefined) {
        updatedInstallments.splice(lastCustomInstallmentIndex + 1, 0, newInstallment);
      }
    }

    // keeping other Charges Separately in last
    updatedInstallments.push(
      updatedInstallments.splice(
        updatedInstallments.findIndex(v => v.lastRow),
        1,
      )[0],
    );

    setFieldValue('clp_installments', updatedInstallments);
  };

  // const handleTotalPaymentCharge = () => {
  //   let total = 0;
  //   values.clp_installments?.forEach(charge => {
  //     total += parseFloat(charge?.clp_installment_amount) || 0;
  //   });

  //   setFieldValue('custom_payment_total_amount', total.toFixed(2));
  //   return total.toFixed(2);
  // };
  const handleDueDateTotal = () => {
    let totalInstallmentAmount = 0;
    let totalPercentage = 0;
    let totalBasicAmount = 0;
    let totalOtherChargesAmount = 0;
    let totalGST = 0;
    let totalExtraChargesAmount = 0;

    // Iterate over grouped installments with due dates
    Object.keys(groupedInstallments).forEach(dueDate => {
      if (dueDate !== 'No Date') {
        groupedInstallments[dueDate].forEach(charge => {
          // Sum up each field
          totalInstallmentAmount += parseFloat(charge?.clp_installment_amount) || 0;
          totalPercentage += parseFloat(charge?.clp_per) || 0;
          totalBasicAmount += parseFloat(charge?.clp_basic_amt) || 0;
          totalOtherChargesAmount += parseFloat(charge?.clp_otherchages_amt) || 0;
          totalGST += parseFloat(charge?.clp_gst) || 0;
          totalExtraChargesAmount += parseFloat(charge?.clp_extrachages_amt) || 0;
        });
      }
    });

    return {
      totalInstallmentAmount: totalInstallmentAmount.toFixed(2),
      totalPercentage: totalPercentage.toFixed(2),
      totalBasicAmount: totalBasicAmount.toFixed(2),
      totalOtherChargesAmount: totalOtherChargesAmount.toFixed(2),
      totalGST: totalGST.toFixed(2),
      totalExtraChargesAmount: totalExtraChargesAmount.toFixed(2),
    };
  };

  const handleNoDueDateTotal = () => {
    let totalInstallmentAmount = 0;
    let totalPercentage = 0;
    let totalBasicAmount = 0;
    let totalOtherChargesAmount = 0;
    let totalGST = 0;
    let totalExtraChargesAmount = 0;

    Object.keys(groupedInstallments).forEach(dueDate => {
      if (dueDate === 'No Date') {
        groupedInstallments['No Date'].forEach(charge => {
          // Sum up each field
          totalInstallmentAmount += parseFloat(charge?.clp_installment_amount) || 0;
          totalPercentage += parseFloat(charge?.clp_per) || 0;
          totalBasicAmount += parseFloat(charge?.clp_basic_amt) || 0;
          totalOtherChargesAmount += parseFloat(charge?.clp_otherchages_amt) || 0;
          totalGST += parseFloat(charge?.clp_gst) || 0;
          totalExtraChargesAmount += parseFloat(charge?.clp_extrachages_amt) || 0;
        });
      }
    });

    return {
      totalInstallmentAmount: totalInstallmentAmount.toFixed(2),
      totalPercentage: totalPercentage.toFixed(2),
      totalBasicAmount: totalBasicAmount.toFixed(2),
      totalOtherChargesAmount: totalOtherChargesAmount.toFixed(2),
      totalGST: totalGST.toFixed(2),
      totalExtraChargesAmount: totalExtraChargesAmount.toFixed(2),
    };
  };

  const handlePaymentScheduleUpdate = (index, field, value) => {
    const newUnitRates = cloneDeep(values.clp_installments);

    // Update the field value
    newUnitRates[index] = {
      ...newUnitRates[index],
      [field]: value,
    };

    // Check if payment installment is empty or null
    if (!newUnitRates[index].clp_payment_installment) {
      newUnitRates[index].clp_installment_amount = 0;
    }

    // Calculate the new installment amount
    const basicAmount = Number(newUnitRates[index].clp_basic_amt) || 0;
    const otherChargesAmt = Number(newUnitRates[index].clp_otherchages_amt) || 0;
    const extraChargesAmt = Number(newUnitRates[index].clp_extrachages_amt) || 0;
    const gst = Number(newUnitRates[index].clp_gst) || 0;

    const totalPaymentSchedule = basicAmount + otherChargesAmt + gst + extraChargesAmt;
    newUnitRates[index].clp_installment_amount = totalPaymentSchedule || 0;

    // Update the state
    setFieldValue('clp_installments', newUnitRates);
  };

  const handleInstallmentChange = e => {
    dispatch(
      getCLPInstallmentData({
        project_id: Number(project_id),
        clp_id: e.value,
      }),
    );

    setFieldValue('clp_bank_id', e.value);
    setSelected(true);
  };

  // installment calculations
  const calculateInstallments = () => {
    let updatedList = cloneDeep(values.clp_installments);

    const filteredInstallments = updatedList.filter(installment => !installment.is_unlinked);
    const installmentLength = filteredInstallments.length > 1 ? filteredInstallments.length - 1 : 1;

    updatedList?.map(installment => {
      installment.clp_basic_amt = 0;
      installment.clp_otherchages_amt = 0;
      installment.clp_extrachages_amt = 0;
      installment.clp_installment_amount = 0;
      installment.clp_gst = 0;

      return installment;
    });

    updatedList?.map(installment => {
      if (!installment.lastRow && !installment?.is_unlinked) {
        const calculatedAmount =
          (Number(values.basic_rate_basic_amount) * installment.clp_per) / 100;

        installment.clp_basic_amt = Number(calculatedAmount);
        installment.clp_installment_amount += Number(calculatedAmount);
      }
      return installment;
    });

    extraCharges?.map(extraCharge => {
      const { extra_charges_distribution_method, extra_charges_amt } = extraCharge;

      switch (extra_charges_distribution_method) {
        case 'Equally with all installments': {
          const equallyDistributedAmount = extra_charges_amt / installmentLength;

          updatedList = filteredInstallments?.map((installment, index) => {
            if (!installment.lastRow && !installment?.is_unlinked && index !== installmentLength) {
              installment.clp_extrachages_amt += Number(equallyDistributedAmount);
              installment.clp_installment_amount += Number(equallyDistributedAmount);
            }
            return installment;
          });
          break;
        }

        case 'Proportionately with all installment': {
          updatedList = filteredInstallments?.map((installment, index) => {
            if (index !== installmentLength && !installment.lastRow && !installment?.is_unlinked) {
              installment.clp_extrachages_amt += Number(
                (extra_charges_amt * installment.clp_per) / 100,
              );
              installment.clp_installment_amount += Number(
                (extra_charges_amt * installment.clp_per) / 100,
              );
            }
            return installment;
          });
          break;
        }

        case 'Proportionately with all installment(Except First)': {
          const proportionatelyDistributedAmount = extra_charges_amt / (installmentLength - 1);

          updatedList = filteredInstallments?.map((installment, index) => {
            if (index !== 0 && !installment.lastRow && !installment?.is_unlinked) {
              installment.clp_extrachages_amt += Number(
                (proportionatelyDistributedAmount * installment.clp_per) / 100,
              );
              installment.clp_installment_amount += Number(
                (proportionatelyDistributedAmount * installment.clp_per) / 100,
              );
            }
            return installment;
          });
          break;
        }

        case 'Connect with last installment': {
          updatedList = filteredInstallments?.map((installment, index) => {
            if (index === installmentLength - 1 && !installment.lastRow && !installment?.clp_date) {
              installment.clp_extrachages_amt += Number(extra_charges_amt);
              installment.clp_installment_amount += Number(extra_charges_amt);
            }
            return installment;
          });
          break;
        }

        case 'Dont connect with installment': {
          updatedList = filteredInstallments?.map(installment => {
            if (
              // !installment?.is_unlinked ||
              installment?.lastRow ||
              installment?.clp_payment_installment === 'Other Charges (Separately)'
            ) {
              installment.clp_extrachages_amt += Number(extra_charges_amt);
              installment.clp_installment_amount += Number(extra_charges_amt);
            }
            return installment;
          });
          break;
        }

        default:
      }
    });

    OCList?.map(oclist => {
      const { other_charges_distribution_method, other_charges_amount } = oclist;

      switch (other_charges_distribution_method) {
        case 'Equally with all installments': {
          const equallyDistributedAmount = other_charges_amount / installmentLength;

          updatedList = filteredInstallments?.map((installment, index) => {
            if (!installment?.lastRow && !installment?.is_unlinked && index !== installmentLength) {
              installment.clp_otherchages_amt += Number(equallyDistributedAmount);
              installment.clp_installment_amount += Number(equallyDistributedAmount);
            }
            return installment;
          });
          break;
        }

        case 'Proportionately with all installment': {
          updatedList = filteredInstallments?.map((installment, index) => {
            if (index !== installmentLength && !installment?.lastRow && !installment?.is_unlinked) {
              installment.clp_otherchages_amt += Number(
                (other_charges_amount * installment.clp_per) / 100,
              );
              installment.clp_installment_amount += Number(
                (other_charges_amount * installment.clp_per) / 100,
              );
            }
            return installment;
          });
          break;
        }

        case 'Proportionately with all installment(Except First)': {
          const proportionatelyDistributedAmount = other_charges_amount / (installmentLength - 1);

          updatedList = filteredInstallments?.map((installment, index) => {
            if (index !== 0 && !installment?.lastRow && !installment?.is_unlinked) {
              installment.clp_otherchages_amt += Number(
                (proportionatelyDistributedAmount * installment.clp_per) / 100,
              );
              installment.clp_installment_amount += Number(
                (proportionatelyDistributedAmount * installment.clp_per) / 100,
              );
            }
            return installment;
          });
          break;
        }

        case 'Connect with last installment': {
          updatedList = filteredInstallments?.map((installment, index) => {
            if (
              index === installmentLength - 1 &&
              !installment?.lastRow &&
              !installment?.is_unlinked
            ) {
              installment.clp_otherchages_amt += Number(Number(other_charges_amount).toFixed(2));
              installment.clp_installment_amount += Number(Number(other_charges_amount).toFixed(2));
            }
            return installment;
          });
          break;
        }

        case 'Dont connect with installment': {
          updatedList = filteredInstallments?.map(installment => {
            if (
              // !installment?.is_unlinked ||
              installment?.lastRow ||
              installment?.clp_payment_installment === 'Other Charges (Separately)'
            ) {
              installment.clp_otherchages_amt += Number(Number(other_charges_amount).toFixed(2));
              installment.clp_installment_amount += Number(Number(other_charges_amount).toFixed(2));
            }
            return installment;
          });
          break;
        }

        default:
      }
    });

    updatedList?.map(installment => {
      const calculatedGSTAmount =
        ((Number(installment.clp_basic_amt) + Number(installment.clp_otherchages_amt)) *
          values.gst_per) /
        100;
      installment.clp_gst = Number(calculatedGSTAmount.toFixed(2));
      installment.clp_installment_amount += Number(calculatedGSTAmount.toFixed(2));

      return installment;
    });

    setFieldValue('clp_installments', updatedList);
  };

  // installment Options
  const installmentOptions = useMemo(() => {
    let installments = installmentsList?.map(e => ({
      label: `${e.id} - ${e.name}`,
      value: e.id,
    }));

    if (values.calculation_method === 'clp_base') {
      installments = installments?.filter(
        singleInstallment => singleInstallment.value === values.clp_bank_id,
      );
    }
    return installments;
  }, [installmentsList, values.calculation_method, values.clp_bank_id]);

  useEffect(() => {
    if (EDIT) {
      setShowInstall(true);
    }
  }, [EDIT]);

  useEffect(() => {
    if (EDIT) {
      const updatedClpInstallments = clpDataPer.map(installment => {
        return {
          ...installment,
          clp_due_date: installment.clp_date || installment.clp_due_date,
        };
      });
      const clpPerTotal = updatedClpInstallments
        ?.filter(installment => installment?.clp_date)
        .reduce((total, installment) => {
          return total + (parseFloat(installment.clp_per) || 0);
        }, 0);

      const unlinkedInstallmentsCount = updatedClpInstallments?.filter(
        installment => installment?.clp_date,
      )?.length;

      setFieldValue('unlink_installment_count', unlinkedInstallmentsCount);
      setFieldValue('unlink_percentage', clpPerTotal);
      setFieldValue('is_unlinked', false);
      // setIsUnlinked(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [EDIT, selected]);

  const groupInstallmentsByDate = installments => {
    return installments.reduce((acc, installment) => {
      const date = installment.clp_date || 'No Date';
      if (!acc[date]) {
        acc[date] = [];
      }
      acc[date].push(installment);
      return acc;
    }, {});
  };

  const groupedInstallments = useMemo(
    () => groupInstallmentsByDate(values.clp_installments),
    [values.clp_installments],
  );

  const handleDeleteInstallment = (index: number) => {
    const updatedInstallments = cloneDeep(values.clp_installments);

    updatedInstallments.splice(index, 1);

    setFieldValue('clp_installments', updatedInstallments);
  };

  // Function to handle Same As CLP button click (remove custom installment)
  const handleSameAsCLP = () => {
    if (isUnlinked) {
      mapPaymentInstallments();
      setFieldValue('unlink_installment_count', 0);
      setFieldValue('unlink_percentage', 0);
      setFieldValue('is_unlinked', false);
      setIsUnlinked(false);
    } else {
      // if (EDIT) {
      //   const selectedInstallment = installmentOptions.find(
      //     option => option.value === values.clp_bank_id,
      //   );

      //   if (selectedInstallment) {
      //     handleInstallmentChange(selectedInstallment);
      //   }
      // }
      calculateInstallments();
    }
  };

  const handleUnlinkInstallments = () => {
    const clpDate = [];

    values?.clp_installments?.map(i => {
      if (i?.clp_date !== null && i?.clp_date !== undefined) {
        clpDate.push(i.clp_date);
      }
    });

    if (clpDate?.length === 0) {
      alert('CLP Date not availble');
      return;
    }
    const updatedInstallments = values.clp_installments.map(installment => {
      if (installment.clp_date) {
        return {
          ...installment,
          is_unlinked: true,
        };
      }
      return installment;
    });
    const updatedClpInstallments = clpDataPer.map(installment => {
      return {
        ...installment,
        clp_due_date: installment.clp_date || installment.clp_due_date,
      };
    });
    const clpPerTotal = updatedClpInstallments
      ?.filter(installment => installment?.clp_date)
      .reduce((total, installment) => {
        return total + (parseFloat(installment.clp_per) || 0);
      }, 0);

    const unlinkedInstallmentsCount = updatedClpInstallments?.filter(
      installment => installment?.clp_date,
    )?.length;
    setFieldValue('unlink_installment_count', unlinkedInstallmentsCount);
    setFieldValue('unlink_percentage', clpPerTotal);
    setFieldValue('clp_installments', updatedInstallments);
    setFieldValue('is_unlinked', true);
    setIsUnlinked(true);
  };

  const totals = handleDueDateTotal();
  const noDateTotals = handleNoDueDateTotal();
  // const clpDate = [];

  // values?.clp_installments?.map(i => {
  //   if (i?.clp_date !== null && i?.clp_date !== undefined) {
  //     clpDate.push(i.clp_date);
  //   }
  // });

  return (
    <div className="booking-form-box shwan-form pt-0 mt-4 shadow-none">
      <div className="col-12" style={{ padding: '0px 40px' }}>
        <div className="form-row">
          <div className="col-6 ">
            <label>Select Payment Installment</label>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                gap: '1rem',
              }}
            >
              <Select
                closeMenuOnSelect={true}
                options={installmentOptions}
                placeholder="Select Payment Installment"
                styles={{
                  container: (base: any) => ({
                    ...base,
                    marginTop: 10,
                    marginBottom: 20,
                    width: '25rem',
                  }),
                }}
                value={installmentOptions?.find(e => e.value === values.clp_bank_id) || null}
                onChange={handleInstallmentChange}
              />
              <button
                className="Btn btn-lightblue-primary lbps-btn py-2"
                id="butng"
                type="button"
                onClick={() => {
                  setShowInstall(true);
                  calculateInstallments();
                }}
              >
                Calculate
              </button>
            </div>
          </div>
        </div>
        <div>
          {showInstall && (
            <>
              {/* {Object.keys(groupedInstallments).some(dueDate => dueDate !== 'No Date') && ( */}
              <div className="d-flex justify-content-between align-items-center mt-3 mb-3">
                <span className="linked-text">Passed Installment</span>
                <div>
                  <button
                    className="Btn btn-lightblue-primary lbps-btn py-2 mr-2"
                    id="butng"
                    style={{
                      backgroundColor: '#rgba(237, 241, 254, 1)',
                      border: isUnlinked ? '1px solid transparent' : '1px solid #058503',
                      borderRadius: 8,
                      color: isUnlinked ? '#4872F4' : '#058503',
                    }}
                    type="button"
                    onClick={() => {
                      handleSameAsCLP();
                    }}
                  >
                    <div className="d-flex justify-content-center">
                      {!isUnlinked && (
                        <svg
                          fill="none"
                          height="18"
                          viewBox="0 0 18 18"
                          width="18"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M12.3701 4.75994C12.0209 4.40686 11.452 4.4024 11.0973 4.75001L4.44483 11.3249L1.55292 8.33109C1.208 7.9716 0.636985 7.9598 0.277533 8.30472C-0.0819573 8.64963 -0.0937567 9.22065 0.25116 9.5801C0.252723 9.58171 0.254247 9.58331 0.25581 9.58491L3.78187 13.239C3.95062 13.4112 4.18125 13.5087 4.42232 13.5097H4.43224C4.66874 13.5089 4.89551 13.4153 5.06367 13.249L12.362 6.03271C12.7146 5.68303 12.7182 5.11411 12.3701 4.75994Z"
                            fill="#058503"
                          />
                          <path
                            d="M9.19383 13.2386C9.36214 13.4103 9.59204 13.5077 9.83248 13.5092H9.8424C10.0789 13.5085 10.3057 13.4149 10.4738 13.2485L17.7722 6.03228C18.103 5.65978 18.0692 5.08966 17.6967 4.75885C17.359 4.45894 16.8516 4.45499 16.5093 4.74959L9.85952 11.3245L9.62228 11.0782C9.2774 10.7187 8.70638 10.7069 8.34689 11.0518C7.9874 11.3967 7.97556 11.9677 8.32048 12.3272C8.32204 12.3289 8.3236 12.3305 8.32517 12.3321L9.19383 13.2386Z"
                            fill="#058503"
                          />
                        </svg>
                      )}
                      <span style={{ marginLeft: 5 }}> Same As CLP</span>
                    </div>
                  </button>
                  <button
                    className="Btn btn-lightblue-primary lbps-btn py-2 mr-0"
                    disabled={isUnlinked}
                    id="butng"
                    style={{
                      backgroundColor: isUnlinked ? '#E5E5E5' : '#4872F4',
                      color: isUnlinked ? '#3D3F43' : '#fff',
                      borderRadius: 8,
                    }}
                    type="button"
                    onClick={() => {
                      handleUnlinkInstallments();
                    }}
                  >
                    <div className="d-flex justify-content-center">
                      <span>Unlink</span>
                    </div>
                  </button>
                </div>
              </div>
              {/* )} */}

              {/* Table for installments with due dates */}
              {/* {Object.keys(groupedInstallments).some(dueDate => dueDate !== 'No Date') && ( */}
              <div className="table-responsive">
                <table className="table custom-table-border w-100">
                  <thead>
                    <tr>
                      <th>Installment Name</th>
                      <th>Date</th>
                      <th>%</th>
                      <th className="text-right">Basic Amount</th>
                      <th className="text-right">Other Charges Amount</th>
                      <th className="text-right">GST</th>
                      <th className="text-right">Extra Charges Amount</th>
                      <th className="text-right">Installment Amount</th>
                    </tr>
                  </thead>
                  <tbody>
                    {Object.keys(groupedInstallments).map(
                      (dueDate, idx) =>
                        dueDate !== 'No Date' && (
                          <React.Fragment key={idx}>
                            {groupedInstallments[dueDate].map((installment, i) => (
                              <CustomLinkInstallmentRow
                                key={i}
                                disabled={isUnlinked}
                                e={installment}
                                formikProps={formikProps}
                                handlePaymentScheduleUpdate={handlePaymentScheduleUpdate}
                                i={i}
                              />
                            ))}
                          </React.Fragment>
                        ),
                    )}
                    <tr
                      className="total-background text-right"
                      style={{ borderTop: 'none', padding: '5px 0px' }}
                    >
                      <td className="text-left">Total</td>
                      <td></td>
                      <td className="text-left">{totals?.totalPercentage} %</td>
                      <td className="text-right">{totals?.totalBasicAmount}</td>
                      <td className="text-right">{totals?.totalOtherChargesAmount}</td>
                      <td className="text-right">{totals?.totalGST}</td>
                      <td className="text-right">{totals?.totalExtraChargesAmount}</td>
                      <td style={{ fontSize: '1.1rem', fontWeight: '600' }}>
                        ₹ {totals?.totalInstallmentAmount}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              {/* )} */}

              <div className="d-flex justify-content-between align-items-center mt-4 mb-3">
                <span className="linked-text">Upcoming Installment</span>
                {isUnlinked && (
                  <div>
                    <button
                      className="Btn btn-lightblue-primary lbps-btn py-2 "
                      id="butng"
                      style={{ backgroundColor: 'rgba(237, 241, 254, 1)', borderRadius: 8 }}
                      type="button"
                      onClick={() => {
                        addPaymentSchedule();
                      }}
                    >
                      Add
                    </button>
                  </div>
                )}
                {EDIT && values?.is_unlinked && !isUnlinked && (
                  <div>
                    <button
                      className="Btn btn-lightblue-primary lbps-btn py-2 "
                      id="butng"
                      style={{ backgroundColor: 'rgba(237, 241, 254, 1)', borderRadius: 8 }}
                      type="button"
                      onClick={() => {
                        addPaymentSchedule();
                      }}
                    >
                      Add
                    </button>
                  </div>
                )}
              </div>
              <div className="table-responsive">
                <table className="table custom-table-border w-100">
                  <thead>
                    <tr>
                      <th>Installment Name</th>
                      {/* {Object.keys(groupedInstallments || {}).some(
                      dueDate => dueDate !== 'No Date',
                    ) &&  */}
                      <th>Date</th>
                      {/* } */}
                      <th>%</th>
                      <th className="text-right">Basic Amount</th>
                      <th className="text-right">Other Charges Amount</th>
                      <th className="text-right">GST</th>
                      <th className="text-right">Extra Charges Amount</th>
                      <th className="text-right">Installment Amount</th>
                    </tr>
                  </thead>
                  <tbody>
                    {groupedInstallments['No Date'] &&
                      groupedInstallments['No Date'].map((installment, i) => (
                        <CustomLinkInstallmentRow
                          key={i}
                          // clpDate={clpDate}
                          e={installment}
                          EDIT={EDIT}
                          formikProps={formikProps}
                          handleDeleteInstallment={handleDeleteInstallment}
                          handlePaymentScheduleUpdate={handlePaymentScheduleUpdate}
                          i={i}
                        />
                      ))}
                    <tr
                      className="total-background text-right"
                      style={{ borderTop: 'none', padding: '5px 0px' }}
                    >
                      <td className="text-left">Total</td>
                      {/* {Object.keys(groupedInstallments || {}).some(
                      dueDate => dueDate !== 'No Date',
                    ) &&  */}
                      <td></td>
                      {/* } */}
                      <td className="text-left">{noDateTotals?.totalPercentage} %</td>
                      <td className="text-right">{noDateTotals?.totalBasicAmount}</td>
                      <td className="text-right">{noDateTotals?.totalOtherChargesAmount}</td>
                      <td className="text-right">{noDateTotals?.totalGST}</td>
                      <td className="text-right">{noDateTotals?.totalExtraChargesAmount}</td>
                      <td style={{ fontSize: '1.1rem', fontWeight: '600' }}>
                        ₹ {noDateTotals?.totalInstallmentAmount}
                      </td>
                      <td></td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default CustomLinkInstallments;
