import './CS_BookingForm.css';

import Loader from 'components/atoms/Loader';
import { useFormik } from 'formik';
import Cookies from 'js-cookie';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { editBooking, getBookingFormDetails } from 'redux/customerSection';
import {
  getBookingFormOwnerFlag,
  getBrokerList,
  getCLPInstallmentOptions,
  parkingList,
} from 'redux/sales';
import { IBroker } from 'redux/sales/salesInterface';
import { useAppDispatch, useAppSelector } from 'redux/store';
import * as Yup from 'yup';

//Sub Components
import BookingHeader from './components/BookingHeader';
import BrokerDetails from './components/BrokerDetails';
import CalculationMethod from './components/CalculationMethod';
import CustomerDetails from './components/CustomerDetails';
import ExtraCharges from './components/ExtraCharges';
import GovtTaxes from './components/GovtTaxes';
import InstallmentSelection from './components/InstallmentSelection';
import Loan from './components/Loan';
import OtherCharges from './components/OtherCharges';
import OverallDiscount from './components/OverallDiscount';
import Parking from './components/Parking';
import Signature from './components/Signature';
import Summary from './components/Summary';
import Terms from './components/Terms';
import UnitInfo from './components/UnitInfo';

const CS_BookingForm = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  // const edit = false;

  // url params
  // const project_id = searchParams.get('project_id');

  // const unit_id = searchParams.get('unitid');
  // const tower_id = searchParams.get('tower_id');
  // const PROJECT_BOOKING_ID = searchParams.get('bookingid');
  // const autoIncremented_Tower = searchParams.get('towerid');
  // const pid = searchParams.get('pid'); //encrypted project id
  // const project_list_id = searchParams.get('project_list_id') || '1';

  const project_id = Cookies.get('_hjproject_idUser_2492391');
  // const project_id = 2;
  const PROJECT_BOOKING_ID = Cookies.get('_hjcsbookingidUser_2492391');
  // const PROJECT_BOOKING_ID = 579; //46
  // const unit_id = 90; //26
  const unit_id = Cookies.get('_hjunit_idUser_2492391');
  const tower_id = Cookies.get('_hjtoweridUser_2492391');
  // const tower_id = 1;
  // const pid = searchParams.get('_hjpidUser_2492391');
  // const project_list_id = searchParams.get('_hjproject_list_idUser_2492391') || '1';

  // old site navigation
  // const OLD_SITE = `${process.env.REACT_APP_REDIRECT_URL}booking_units/${pid}/${project_list_id}/6/${tower_id}`;

  // redux state values
  const { booking_validation, parkingInfo } = useAppSelector(s => s.sales);
  const { bookingdetails, loading } = useAppSelector(s => s.customersection);

  const PERCENTAGE_CLASSIFICATION_REQUIRED =
    booking_validation?.clp_bank_onoff === 'yes' ? true : false;

  const e_SIGNATURE_REQUIRED = booking_validation?.booking_signature === 'yes' ? true : false;
  const GOVERNMENT_REQUIRED = booking_validation?.gov_mandatory_switch === 'yes' ? true : false;
  const PARKING_SWITCH = booking_validation?.parking_module_switch_on_off === 'yes' ? true : false;

  const [isToggle, setIsToggle] = useState(false);
  const [extraCharges, setExtraCharges] = useState([]);
  const [baseAmount, setBaseAmount] = useState<number>();
  const [OCList, setOCList] = useState([]);
  const [brokerDetails, setBrokerDetails] = useState<IBroker>();

  const toggleLoanSwitch = () => {
    setIsToggle(!isToggle);
  };

  const ParkingOption = parkingInfo?.map(e => ({
    label: `${e.parking_no}`,
    value: e.id,
  }));

  const installmentsFromCustom =
    bookingdetails?.custom_installments?.map(installment => ({
      custom_payment_no: installment.custom_payment_no,
      custom_payment_installment: installment.custom_payment_installment,
      installment_due_date: installment.custom_amount_date,
      installment_per: installment.custom_percentage,
      installment_basic_amt: installment.custom_basic_amt,
      installment_otherchages_amt: installment.custom_installment_otherchages_amt,
      installment_extrachages_amt: installment.custom_installment_extrachages_amt,
      installment_amount: installment.custom_amount,
      gst: installment.custom_gst,
    })) || [];

  const installmentsFromCLP =
    bookingdetails?.custom_installments?.map(installment => ({
      clp_payment_no: installment.custom_payment_no,
      clp_payment_installment: installment.custom_payment_installment,
      clp_per: installment.custom_percentage,
      clp_basic_amt: installment.custom_basic_amt,
      clp_otherchages_amt: installment.custom_installment_otherchages_amt,
      clp_extrachages_amt: installment.custom_installment_extrachages_amt,
      clp_installment_amount: installment.custom_amount,
      clp_gst: installment.custom_gst,
      clp_due_date: installment?.custom_amount_date,
    })) || [];

  // form data
  const { visitors_details, broker_details, unit_details } = bookingdetails || {};

  // form initials
  const initialValues = useMemo(() => {
    const {
      unitid,
      visitors_id,
      calculation_method,
      area_for_super_buildup,
      rate_super_buildup,
      basic_rate_disc_amt,
      basic_rate_disc_per,
      other_charges,
      gst_amount,
      gst_per,
      stampduty_amount,
      stampduty_per,
      reg_amount,
      reg_per,
      disc_remarks,
      is_loan,
      loan_amount,
      payment_remark,
      bank,
      through_broker,
      total_other_charges,
      custom_payment_remark,
      extra_charges,
      installment_type,
      signature,
      from_bank,
      from_customer,
      final_plus_addon_charges_input,
      visitors_details,
      broker_details,
      broker_remark,
      brokerage,
      broker_id,
      clp_bank_id,
      parking_no,
      is_unlinked,
      unlink_installment_count,
      unlink_percentage,
    } = bookingdetails || {};

    const { unit_reserviation_date } = unit_details || {};

    const totalInstallmentAmount = installmentsFromCustom.reduce((total: number, installment) => {
      return total + (parseFloat(installment.installment_amount.toString()) || 0);
    }, 0);

    const totalCLPInstallmentAmount = installmentsFromCLP.reduce((total: number, installment) => {
      return total + (parseFloat(installment.clp_installment_amount.toString()) || 0);
    }, 0);

    return {
      visitors_id,
      unit_id: unitid,
      unit_reserved_date: unit_reserviation_date,
      calculation_method:
        calculation_method === 'fixied_amt'
          ? 'fixed_amount'
          : calculation_method === 'clp_base'
          ? 'clp_base'
          : 'rate_base',
      basic_rate_area: area_for_super_buildup,
      basic_rate: rate_super_buildup,
      basic_rate_disc_amt,
      basic_rate_disc_per,
      other_charges: other_charges?.length ? other_charges : [],
      gst_amt: Number(gst_amount),
      gst_per,
      stampduty_amount,
      stampduty_per,
      reg_amount,
      reg_per,
      disc_remarks,
      is_loan,
      loan_amt: loan_amount,
      loan_remarks: payment_remark,
      bank,
      other_charges_total: total_other_charges,
      custom_payment_remark,
      extra_charges: extra_charges?.length ? extra_charges : [],
      installment_type,
      installments: installmentsFromCustom || [],
      clp_installments: installmentsFromCLP || [],
      signature,
      from_bank,
      from_customer,
      custom_payment_total_amount:
        installment_type === 'custom_installments'
          ? totalInstallmentAmount?.toFixed(2)
          : totalCLPInstallmentAmount?.toFixed(2),
      customer_first_name: visitors_details?.first_name,
      customer_last_name: visitors_details?.last_name,
      customer_email: visitors_details?.email,
      customer_phone: visitors_details?.phone,
      broker_first_name: broker_details?.first_name,
      broker_last_name: broker_details?.last_name,
      broker_email: broker_details?.email,
      broker_phone: broker_details?.phone,
      broker_remark,
      brokerage,
      through_broker: through_broker === 'yes' ? true : false,
      broker_id,
      property_final_amount: final_plus_addon_charges_input,
      clp_bank_id,
      parking_no,
      is_unlinked: is_unlinked === 1 ? true : false,
      unlink_installment_count,
      unlink_percentage,
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bookingdetails]);

  // Api calls
  useEffect(() => {
    dispatch(
      getBookingFormDetails({
        project_id: Number(project_id),
        project_booking_id: Number(PROJECT_BOOKING_ID),
        unit_id: Number(unit_id),
      }),
    );
    dispatch(getBrokerList({ project_id: String(project_id) }));
    dispatch(
      getCLPInstallmentOptions({ project_id: Number(project_id), tower_id: Number(tower_id) }),
    );
    dispatch(
      parkingList({
        project_id: String(project_id),
        unit_id: String(unit_id),
        type: PROJECT_BOOKING_ID ? 'edit' : '',
      }),
    );
    dispatch(getBookingFormOwnerFlag({ project_id: String(project_id) }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Charges list populate
  useEffect(() => {
    // OC
    handleMapOtherCharges();
    handle_Other_Charge_Row_Total();
    // EC
    handleUpdateExtraCharges();
    handle_Extra_Charge_Row_Total();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bookingdetails]);

  // other charges update, delete
  function handleMapOtherCharges() {
    const OC = bookingdetails?.other_charges;

    const updatedData = OC?.map(x => {
      const amt =
        String(x.other_charges_distribution_method) === '' ||
        x.other_charges_distribution_method === 0 ||
        String(x.other_charges_distribution_method) === '0'
          ? 0
          : Number(x.other_charges_area)
          ? Number(x.other_charges_area) * Number(x.other_charges_rate) -
            Number(x.other_charges_disc_amt)
          : Number(x.other_charges_rate) - Number(x.other_charges_disc_amt);
      return {
        other_charges_no: x.other_charges_no,
        other_charges_title: x.chargename,
        other_charges_distribution_method: x.other_charges_distribution_method || '',
        other_charges_area: Number(x.other_charges_area) || 0,
        other_charges_rate: Number(x.other_charges_rate),
        other_charges_disc_amt: Number(x.other_charges_disc_amt) || 0,
        other_charges_disc_per: Number(x.other_charges_disc_per) || 0,
        other_charges_amount: amt,
        other_charges_base: 0,
      };
    });

    setOCList(updatedData);
  }

  const handleTotalOtherCharge = useCallback(() => {
    let total = 0;
    OCList?.forEach(charge => {
      total += parseFloat(charge?.other_charges_amount) || 0;
    });
    return Number(total).toFixed(2);
  }, [OCList]);

  const handleTotalOtherDiscountAmt = useCallback(() => {
    let total = 0;
    OCList?.forEach(charge => {
      total += parseFloat(charge?.other_charges_disc_amt) || 0;
    });
    return Number(total).toFixed(2);
  }, [OCList]);

  function handle_Other_Charge_Row_Total() {
    OCList?.map((x, index) => {
      const calculatedAmount = x.other_charges_area
        ? x.other_charges_area * x.other_charges_rate
        : x.other_charges_rate;

      const otherChargesAmount =
        x?.other_charges_distribution_method === '' ||
        x?.other_charges_distribution_method === 0 ||
        x?.other_charges_distribution_method === '0'
          ? 0
          : calculatedAmount.toFixed(2) - x.other_charges_disc_amt;

      setOCList(prevList => {
        const newUnitRates = [...prevList];
        newUnitRates[index] = {
          ...newUnitRates[index],
          other_charges_amount: otherChargesAmount,
        };
        return newUnitRates;
      });
    });
  }

  const handleOCListChange = (index, field, value) => {
    setOCList(prevList => {
      const newUnitRates = [...prevList];
      newUnitRates[index] = {
        ...newUnitRates[index],
        [field]: value,
      };

      const OC_ROW_BASE_AMT = newUnitRates[index].other_charges_area
        ? newUnitRates[index].other_charges_area * newUnitRates[index].other_charges_rate
        : newUnitRates[index].other_charges_rate;

      const discountAmt = newUnitRates[index].other_charges_disc_amt;

      const totalAmount = OC_ROW_BASE_AMT === 0 ? 0 : Number(OC_ROW_BASE_AMT) - Number(discountAmt);

      newUnitRates[index].other_charges_amount = totalAmount.toFixed(2);

      // resetGovTaxes();

      return [...newUnitRates];
    });
  };

  // extra charges update, delete & add
  function handleUpdateExtraCharges() {
    const EC = bookingdetails?.extra_charges;

    const updatedData = EC?.map(x => ({
      extra_charges_no: x.extra_charges_no,
      extra_charges_title: x.extra_charges_title,
      extra_charges_distribution_method: x.extra_charges_distribution_method || '',
      extra_charges_area: Number(x.extra_charges_area) || 0,
      extra_charges_rate: Number(x.extra_charges_rate),
      extra_charges_disc_amt: Number(x.extra_charges_disc_amt) || 0,
      extra_charges_disc_per: Number(x.extra_charges_disc_per) || 0,
      extra_charges_total: 0,
      extra_charges_base: 0,
    }));

    setExtraCharges(updatedData);

    handle_Extra_Charge_Row_Total();
  }

  const handleUpdateExtraCharge = (index: number, field: string, value) => {
    setExtraCharges(prevExtraCharges => {
      const updatedExtraCharges = [...prevExtraCharges];
      updatedExtraCharges[index][field] = value;

      const EXTRA_CHARGE_BASE =
        updatedExtraCharges[index].extra_charges_area > 0
          ? updatedExtraCharges[index].extra_charges_area *
            updatedExtraCharges[index].extra_charges_rate
          : updatedExtraCharges[index].extra_charges_rate || 0;

      const discountAmt = updatedExtraCharges[index].extra_charges_disc_amt;

      const totalAmount = EXTRA_CHARGE_BASE === 0 ? 0 : EXTRA_CHARGE_BASE - discountAmt;

      updatedExtraCharges[index].extra_charges_total = totalAmount;

      return updatedExtraCharges;
    });
  };

  function handle_Extra_Charge_Row_Total() {
    setExtraCharges(prevList =>
      prevList?.map(x => {
        const Amt =
          x.extra_charges_area > 0
            ? x.extra_charges_area * x.extra_charges_rate
            : x.extra_charges_rate;

        const extraChargesAmount =
          x?.extra_charges_distribution_method === '' ||
          x?.extra_charges_distribution_method === 0 ||
          x?.extra_charges_distribution_method === '0'
            ? 0
            : Amt - x.extra_charges_disc_amt;

        return {
          ...x,
          extra_charges_total: extraChargesAmount,
        };
      }),
    );
  }

  const handleDeleteExtraCharge = (index: number) => {
    setExtraCharges(prevExtraCharges => {
      const updatedExtraCharges = [...prevExtraCharges];
      updatedExtraCharges.splice(index, 1);
      return updatedExtraCharges;
    });
  };

  const handleTotalExtraCharge = () => {
    let total = 0;
    extraCharges?.forEach(charge => {
      total += charge.extra_charges_total || 0;
    });

    return Number(total).toFixed(2);
  };

  const handleExtraChargeAdd = () => {
    setExtraCharges([
      ...extraCharges,
      {
        extra_charges_no: extraCharges.length + 1,
        extra_charges_title: '',
        extra_charges_distribution_method: '',
        extra_charges_area: 0,
        extra_charges_rate: 0,
        extra_charges_disc_amt: 0,
        extra_charges_disc_per: 0,
        extra_charges_total: 0,
        extra_charges_base: 0,
      },
    ]);
  };

  // booking form submission
  const onSubmit = async values => {
    const {
      // project_id,
      unit_id,
      visitors_id,
      broker_id,
      through_broker,
      brokerage,
      broker_remark,
      unit_reserved_date,
      calculation_method,
      basic_rate_area,
      basic_rate,
      basic_rate_disc_amt,
      basic_rate_disc_per,
      basic_rate_basic_amount,
      disc_remarks,
      gst_per,
      gst_amt,
      stampduty_per,
      stampduty_amount,
      reg_per,
      reg_amount,
      custom_payment_remark,
      custom_payment_total_amount,
      bank,
      loan_amt,
      installments,
      clp_installments,
      installment_type,
      custom_payment_remark_id,
      clp_bank_id,
      from_bank,
      from_customer,
      signature,
      customer_first_name,
      customer_last_name,
      customer_email,
      customer_phone,
      property_final_amount,
      parking_no,
      is_unlinked,
      unlink_percentage,
      unlink_installment_count,
    } = values;

    const response = await dispatch(
      editBooking({
        project_bookings_id: Number(PROJECT_BOOKING_ID) || 0,
        project_id: Number(project_id),
        unit_id,
        visitors_id,
        customer_first_name,
        customer_last_name,
        customer_email,
        customer_phone,
        broker_id: through_broker === false ? 0 : broker_id,
        through_broker: through_broker ? 'yes' : 'no',
        brokerage: through_broker === false ? '' : brokerage,
        broker_remark: through_broker === false ? '' : broker_remark,
        unit_reserved_date,
        parking_no,
        calculation_method:
          calculation_method === 'rate_base'
            ? 'rate_base'
            : calculation_method === 'clp_base'
            ? 'clp_base'
            : 'fixied_amt',
        basic_rate_no: 1,
        basic_rate_description: 'Basic rate of unit',
        basic_rate_area,
        basic_rate: basic_rate || 0,
        basic_rate_disc_amt,
        basic_rate_disc_per: basic_rate_disc_per || 0,
        basic_rate_basic_amount,
        other_charges: OCList?.length
          ? OCList
          : [
              {
                unit_other_charge_id: 0,
                other_charges_no: 0,
                other_charges_title: '',
                other_charges_distribution_method: '',
                other_charges_area: 0,
                other_charges_rate: 0,
                other_charges_disc_amt: 0,
                other_charges_disc_per: 0,
                other_charges_amount: 0,
              },
            ],
        other_charges_total: parseFloat(handleTotalOtherCharge()),
        sub_total_amt: basic_rate_basic_amount + parseFloat(handleTotalOtherCharge()),
        total_disc:
          parseFloat(handleTotalOtherDiscountAmt()) + parseFloat(values.basic_rate_disc_amt),
        disc_remarks: disc_remarks,
        gst_per,
        gst_amt,
        stampduty_per,
        stampduty_amount,
        reg_per,
        reg_amount,
        total_gove_tax: String(
          Number(values.gst_amt || 0) +
            Number(values.stampduty_amount || 0) +
            Number(values.reg_amount || 0),
        ),
        extra_charges: extraCharges.length
          ? extraCharges
          : [
              {
                extra_charges_no: '',
                extra_charges_title: '',
                extra_charges_distribution_method: '',
                extra_charges_area: '',
                extra_charges_rate: '',
                extra_charges_disc_per: '',
                extra_charges_disc_amt: '',
                extra_charges_amt: '',
              },
            ],
        extra_charges_total: parseFloat(handleTotalExtraCharge()),
        property_final_amount:
          Number(values.final_plus_addon_charges_input) === Number(property_final_amount)
            ? Number(property_final_amount)
            : parseFloat(values.basic_rate_basic_amount) +
              parseFloat(handleTotalOtherCharge()) +
              Number(values.gst_amt) +
              Number(values.stampduty_amount) +
              Number(values.reg_amount) +
              parseFloat(handleTotalExtraCharge()),
        is_loan: isToggle ? 'yes' : 'no',
        loan_amt,
        bank,
        loan_remarks: values.loan_remarks,
        installment_type,
        clp_installments: clp_installments.length
          ? clp_installments
          : {
              clp_payment_no: 0,
              clp_payment_installment: '',
              clp_per: '',
              clp_basic_amt: '',
              clp_otherchages_amt: '',
              clp_extrachages_amt: '',
              clp_installment_amount: '',
              clp_gst: '',
            },
        unlink_installment_count,
        unlink_percentage,
        is_unlinked,
        installments: installments.length
          ? installments
          : [
              {
                custom_payment_no: 0,
                custom_payment_installment: '',
                installment_due_date: '',
                installment_per: '',
                installment_basic_amt: 0,
                installment_otherchages_amt: 0,
                installment_extrachages_amt: 0,
                installment_amount: 0,
                gst: 0,
              },
            ],
        custom_payment_total_amount,
        custom_payment_remark_id,
        custom_payment_remark,
        clp_bank_id,
        from_customer,
        from_bank,
        signature,
        is_verified_otp: '',
        is_verified_email_otp: '',
      }),
    );
    if (response.payload.status) {
      navigate(-1);
    }
  };

  const Schema = Yup.object({
    // visitors_id: Yup.string().required('Customer is required'),
    // clp_bank_id: PERCENTAGE_CLASSIFICATION_REQUIRED
    //   ? Yup.number().required('Please select percentage classification')
    //   : null,
    installment_type: Yup.string(),
    from_customer: Yup.string()
      .when('installment_type', {
        is: (value: string) => value === 'clp_installments' && PERCENTAGE_CLASSIFICATION_REQUIRED,
        then: schema => schema.required('Classification is required field'),
      })
      .nullable(),
    through_broker: Yup.string(),
    broker_id: Yup.string()
      .nullable()
      .when('through_broker', {
        is: (value: string) => value === 'true',
        then: schema =>
          schema.required('You`ve selected through broker, please provide broker details.'),
      }),
    signature: Yup.string()
      .when({
        is: () => e_SIGNATURE_REQUIRED,
        then: schema => schema.required('Please add customer signature.'),
      })
      .nullable(),
    installments: Yup.array().of(
      Yup.object()
        .shape({
          installment_due_date: Yup.string().when('installment_type', {
            is: (value: string) => value === 'custom_installments',
            then: schema => schema.required('Installments due date is required'),
          }),
        })
        .nullable(),
    ),
    gst_per: Yup.number()
      .when({
        is: () => GOVERNMENT_REQUIRED,
        then: schema =>
          schema
            .required('Please add GST percentage')
            .test('positive', 'Please add GST percentage', value => value > 0),
      })
      .nullable(),
    gst_amt: Yup.number()
      .when({
        is: () => GOVERNMENT_REQUIRED,
        then: schema =>
          schema
            .required('Please add GST amount')
            .test('positive', 'Please add GST amount', value => value > 0),
      })
      .nullable(),
    stampduty_per: Yup.number()
      .when({
        is: () => GOVERNMENT_REQUIRED,
        then: schema =>
          schema
            .required('Please add Stampduty percentage')
            .test('positive', 'Please add Stampduty percentage', value => value > 0),
      })
      .nullable(),
    stampduty_amount: Yup.number()
      .when({
        is: () => GOVERNMENT_REQUIRED,
        then: schema =>
          schema
            .required('Please add Stampduty amount')
            .test('positive', 'Please add Stampduty amount', value => value > 0),
      })
      .nullable(),
    reg_per: Yup.number()
      .when({
        is: () => GOVERNMENT_REQUIRED,
        then: schema =>
          schema
            .required('Please add Registraion percentage')
            .test('positive', 'Please add Registraion percentage', value => value > 0),
      })
      .nullable(),
    reg_amount: Yup.number()
      .when({
        is: () => GOVERNMENT_REQUIRED,
        then: schema =>
          schema
            .required('Please add Registraion amount')
            .test('positive', 'Please add Registraion amount', value => value > 0),
      })
      .nullable(),
  });

  const validate = () => {
    const errors: any = {};

    // from_customer, from_bank

    const {
      installments,
      basic_rate_basic_amount,
      gst_amt,
      loan_amt = 0,
      clp_installments,
      unlink_percentage,
      is_unlinked,
    } = values;

    const property_amount =
      Number(basic_rate_basic_amount || 0) +
      Number(gst_amt || 0) +
      Number(handleTotalOtherCharge() || 0);

    // const clp_bank_percent = Number(from_customer) + Number(from_bank);

    const totalPercentage = installments.reduce(
      (total, installment) => total + parseFloat(installment.installment_per || 0),
      0,
    );

    if (totalPercentage > 100 && values.installment_type === 'clp_installments') {
      errors.extraErrors = 'Installments total percentage cannot exceed more than 100%';
    }

    // if (clp_bank_percent < 100 && values.installment_type === 'clp_installments') {
    //   errors.from_customer = 'Percentage Classifications total percentage should be 100%';
    // }

    // if (clp_bank_percent > 100 && values.installment_type === 'clp_installments') {
    //   errors.from_customer = 'Percentage Classifications total percentage should be 100%';
    // }

    if (Number(loan_amt) > Number(property_amount)) {
      errors.bankLoanError = 'Loan amount cannot be greater than property amount';
    }

    if (values.installment_type === 'clp_installments') {
      const customInstallments = clp_installments.filter(
        installment => installment.clp_payment_no === '0' || installment.clp_payment_no === 0,
      );

      if (customInstallments.length > 0) {
        // Check if any custom installment has clp_per as 0
        const hasZeroCustomPer = customInstallments.some(
          installment => Number(installment.clp_per) === 0,
        );
        if (hasZeroCustomPer) {
          errors.clp_perZeroError = 'Custom installments cannot have a percentage of 0';
        } else {
          // Calculate the total percentages for custom and unlinked installments
          const customPer = customInstallments.reduce(
            (total, installment) => total + Number(installment.clp_per),
            0,
          );
          // Check if the percentages match
          if (unlink_percentage !== customPer) {
            errors.clp_perMismatch =
              'The total percentage for unlinked installments must match the custom installment percentage';
          }

          // Check for missing due dates in custom installments
        }
      }
      const missingDueDates = customInstallments.filter(installment => !installment.clp_due_date);
      if (missingDueDates.length > 0) {
        errors.clp_due_dateRequired = 'The due date is required for custom installments';
      }
      if (is_unlinked) {
        const unlinkedInstallments = clp_installments.filter(
          installment => installment.is_unlinked !== true,
        );

        const totalUnlinkedPer = unlinkedInstallments.reduce(
          (total, installment) => total + Number(installment.clp_per || 0),
          0,
        );

        if (totalUnlinkedPer !== 100) {
          errors.clp_perUnlinkedError = 'The total percentage for CLP installments must be 100%';
        }
      }
    }
    return errors;
  };

  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    onSubmit: onSubmit,
    validationSchema: Schema,
    validateOnChange: false,
    validateOnBlur: false,
    validate: validate,
  });

  const { values, setFieldValue } = formik;

  // govt Taxes
  useEffect(() => {
    const { basic_rate_area = 0, basic_rate = 0, calculation_method } = values;

    const basic_rate_total = basic_rate_area * basic_rate;
    setBaseAmount(
      calculation_method === 'rate_base' || calculation_method === 'clp_base'
        ? basic_rate_total
        : basic_rate,
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.basic_rate_area, values.basic_rate, values.calculation_method, bookingdetails]);

  useEffect(() => {
    const { basic_rate_disc_amt = 0 } = values;

    // if (values.calculation_method === 'rate_base' || values.calculation_method === 'fixed_amount') {
    setFieldValue(
      'basic_rate_basic_amount',
      parseFloat((baseAmount - basic_rate_disc_amt).toFixed(2)),
    );
    // }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [baseAmount, values.basic_rate_disc_amt, values.basic_rate_disc_per]);

  useEffect(() => {
    formik.setValues({
      ...formik.values,
      basic_rate:
        values.calculation_method === 'rate_base'
          ? Number(bookingdetails?.area_for_super_buildup) *
            Number(bookingdetails?.rate_super_buildup)
          : Number(bookingdetails?.rate_super_buildup) || 0,
      basic_rate_disc_amt: bookingdetails?.basic_rate_disc_amt,
      basic_rate_disc_per: bookingdetails?.basic_rate_disc_per,
      // basic_rate_basic_amount: bookingdetails?.basic_rate_basic_amount,
    });
    if (bookingdetails?.installment_type === 'clp_installments') {
      setFieldValue('clp_installments', installmentsFromCLP || []);
    }
    if (bookingdetails?.installment_type === 'custom_installments') {
      setFieldValue('installments', installmentsFromCustom || []);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.calculation_method]);

  useEffect(() => {
    if (values.is_loan === 'yes') {
      setIsToggle(true);
    } else {
      setIsToggle(false);
    }
    formik.setValues({
      ...formik.values,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.is_loan]);

  // const resetGovTaxes = () => {
  //   setFieldValue('gst_amt', 0);
  //   setFieldValue('stampduty_amount', 0);
  //   setFieldValue('reg_amount', 0);
  //   setFieldValue('gst_per', 0);
  //   setFieldValue('stampduty_per', 0);
  //   setFieldValue('reg_per', 0);
  // };

  return (
    <>
      <Loader loading={loading} />

      {/* Header */}
      <BookingHeader
        customerDetails={visitors_details}
        formikProps={formik}
        navigate={navigate}
        project_id={project_id}
      />

      <section className="booking-form-sec pt-0 bookingFormUpdated">
        <div className="booking-form-row">
          {/* 1st section */}
          <UnitInfo formikProps={formik} unit_details={unit_details} />

          {/* 2nd section */}
          <CustomerDetails visitors_details={visitors_details} />

          {/* 3rd section */}
          <BrokerDetails
            broker_details={broker_details}
            brokerDetails={brokerDetails}
            formikProps={formik}
            setBrokerDetails={setBrokerDetails}
          />

          <Parking
            // EDIT={EDIT}
            formikProps={formik}
            PARKING_SWITCH={PARKING_SWITCH}
            parkingInfo={parkingInfo}
            ParkingOption={ParkingOption}
          />

          {/* 4th section */}
          <CalculationMethod
            baseAmount={baseAmount}
            formikProps={formik}
            // resetGovTaxes={resetGovTaxes}
          />

          {/* 5th section */}
          <OtherCharges
            formikProps={formik}
            handleOCListChange={handleOCListChange}
            handleTotalOtherCharge={handleTotalOtherCharge}
            OCList={OCList}
          />

          {/* 6th section */}
          <OverallDiscount
            formikProps={formik}
            handleTotalOtherDiscountAmt={handleTotalOtherDiscountAmt}
          />

          {/* 7th section */}
          <GovtTaxes formikProps={formik} handleTotalOtherCharge={handleTotalOtherCharge} />

          {/* 8th section */}
          <ExtraCharges
            extraCharges={extraCharges}
            formikProps={formik}
            handle_Extra_Charge_Row_Total={handle_Extra_Charge_Row_Total}
            handleDeleteExtraCharge={handleDeleteExtraCharge}
            handleExtraChargeAdd={handleExtraChargeAdd}
            handleTotalExtraCharge={handleTotalExtraCharge}
            handleUpdateExtraCharge={handleUpdateExtraCharge}
          />

          {/* 9th section */}
          <Summary
            formikProps={formik}
            handleTotalExtraCharge={handleTotalExtraCharge}
            handleTotalOtherCharge={handleTotalOtherCharge}
            handleTotalOtherDiscountAmt={handleTotalOtherDiscountAmt}
          />

          {/* 10th section */}
          <Loan formikProps={formik} isToggle={isToggle} toggleLoanSwitch={toggleLoanSwitch} />

          {/* 11th section */}
          <InstallmentSelection
            extraCharges={extraCharges}
            formikProps={formik}
            handleTotalExtraCharge={handleTotalExtraCharge}
            handleTotalOtherCharge={handleTotalOtherCharge}
            handleTotalOtherDiscountAmt={handleTotalOtherDiscountAmt}
            OCList={OCList}
            project_id={project_id}
            tower_id={tower_id}
          />

          {/* 12th section */}
          <Terms customerDetails={visitors_details} formikProps={formik} project_id={project_id} />

          {/* 13th section */}
          <Signature formikProps={formik} />
        </div>
      </section>
    </>
  );
};

export default CS_BookingForm;
