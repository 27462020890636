import './BookingApproval.css';

import { ArrowBack as ArrowBackIcon, Close, FilterList, Search } from '@mui/icons-material';
import { Badge, Box, Divider, IconButton, TableContainer, Typography } from '@mui/material';
import Loader from 'components/atoms/Loader';
import Cookies from 'js-cookie';
import { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { getProjectModules, getProjectPermissions } from 'redux/project';
import { getBookingApprovalList } from 'redux/sales';
import { useAppDispatch, useAppSelector } from 'redux/store';
import { getPermissions } from 'utils';
import { GLOBAL_STATUS, rowsPerPage } from 'utils/constant';

import ApprovalFilters from './components/ApprovalFilters';

const BookingApprovalTable = ({
  bookingApprovalList,
  project_id,
  token,
  modulePermission,
  user_id,
  // isProjectAdmin,
  approvalFilter,
  pid,
}) => {
  const [page, setPage] = useState(0);
  const [searchTerm, setSearchTerm] = useState('');
  const [open, setOpen] = useState(false);
  const [searchApproval, setSearchApproval] = useState([]);

  const navigate = useNavigate();

  const processedBookingApprovals = useMemo(() => {
    const processedList =
      (modulePermission?.editor || modulePermission?.add) && !modulePermission?.admin
        ? bookingApprovalList?.filter(v => Number(v?.user_id) === Number(user_id))
        : bookingApprovalList;
    return processedList;
  }, [
    modulePermission?.editor,
    modulePermission?.add,
    modulePermission?.admin,
    bookingApprovalList,
    user_id,
  ]);

  const filterCount = useMemo(() => {
    return Object.values(approvalFilter)?.filter(i => i !== '').length;
  }, [approvalFilter]);

  const exist = v => {
    return v !== undefined && v !== null && v !== '';
  };

  const filteredApprovals = useMemo(() => {
    if (filterCount) {
      const { status, evaluated_by } = approvalFilter;

      return processedBookingApprovals.filter(i => {
        const validations = [
          exist(evaluated_by)
            ? evaluated_by.map(v => v.value)?.includes(i?.evaluated_by)
            : undefined,
          exist(status) ? status === i?.status : undefined,
        ];
        return validations.filter(v => v === false).length === 0;
      });
    }
    return processedBookingApprovals;
  }, [approvalFilter, filterCount, processedBookingApprovals]);

  const handleChangePage = event => {
    const newPage = parseInt(event.currentTarget.value) - 1;
    setPage(newPage);
  };

  useEffect(() => {
    setSearchApproval(filteredApprovals);
  }, [filteredApprovals]);

  const handleSearchButtonClick = () => {
    if (!searchTerm) {
      setSearchApproval(filteredApprovals);
    } else {
      const filteredData = filteredApprovals?.filter(approvalListRow => {
        const fullName =
          `${approvalListRow?.first_name} ${approvalListRow?.last_name}`.toLowerCase();
        if (!searchTerm) return true;
        return (
          approvalListRow.unit_info.toLowerCase().includes(searchTerm.toLowerCase()) ||
          approvalListRow.bookedby.toLowerCase().includes(searchTerm.toLowerCase()) ||
          approvalListRow.status.toLowerCase().includes(searchTerm.toLowerCase()) ||
          approvalListRow.evaluated_by.toLowerCase().includes(searchTerm.toLowerCase()) ||
          fullName?.includes(searchTerm.toLowerCase()) ||
          approvalListRow?.phone?.includes(searchTerm)
        );
      });
      setSearchApproval(filteredData);
      setPage(0);
    }
  };

  const handleClearButton = () => {
    setSearchTerm('');
    setSearchApproval(filteredApprovals);
  };

  const navtoDetail = (unitid, bookingid, tower_id, floor_id) => {
    navigate(`/bookingPreview`, {
      state: { unitid, bookingid, project_id, tower_id, floor_id, token, pid },
    });
  };

  // old site navigation
  const OLD_SITE = `${process.env.REACT_APP_REDIRECT_URL}project/${pid}`;

  const handleBack = () => {
    window.location.replace(OLD_SITE);
  };

  const toggleDrawer = () => {
    setOpen(!open);
  };

  const pendingCount = processedBookingApprovals?.filter(v => v.status === 'pending')?.length;
  const approvedCount = processedBookingApprovals?.filter(v => v.status === 'approved')?.length;
  const rejectedCount = processedBookingApprovals?.filter(v => v.status === 'rejected')?.length;

  return (
    <Box sx={{ background: '#fff' }}>
      <Box className="mx-4 bookingApprovalBody">
        <div className="d-flex align-items-center justify-content-between pt-2">
          <div className="d-flex align-items-center my-3">
            <IconButton
              style={{ backgroundColor: '#e5eafa', color: '#4872f4' }}
              onClick={handleBack}
            >
              <ArrowBackIcon />
            </IconButton>
            <Typography className="ml-2" variant="h5">
              Booking Approval
            </Typography>
          </div>
          <div className="d-flex align-items-center">
            <div className="d-flex sale-module-sec align-items-center" style={{ paddingLeft: 0 }}>
              <div className="d-flex justify-content-around align-items-center">
                <div className="booking-form-header new-booking-header visitor-list-top-tabs ml-auto">
                  <h5 className="v_total">
                    Pending<span>{pendingCount}</span>
                  </h5>
                  <h5 className="v_weekly">
                    Approved<span>{approvedCount}</span>
                  </h5>
                  <h5 className="v_monthly">
                    Rejected <span>{rejectedCount}</span>
                  </h5>
                </div>
              </div>
            </div>
            <div>
              <IconButton
                aria-label="filter"
                color="primary"
                sx={{ backgroundColor: 'rgb(229 234 250)' }}
                onClick={toggleDrawer}
              >
                <Badge
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                  }}
                  badgeContent={filterCount}
                  color="primary"
                >
                  <FilterList />
                </Badge>
              </IconButton>
            </div>
          </div>
        </div>

        <div className="d-flex align-items-center mb-3 ">
          <Box
            noValidate
            autoComplete="off"
            className="shwan-form"
            component="form"
            sx={{
              '& > :not(style)': { width: '45ch' },
            }}
          >
            <div className="position-relative">
              <div className="d-flex align-items-center">
                <input
                  className="form-control"
                  placeholder="Search Here"
                  style={{ height: '48px' }}
                  value={searchTerm}
                  onChange={event => setSearchTerm(event.target.value)}
                  onKeyDown={event => {
                    if (event.key === 'Enter') {
                      event.preventDefault();
                      handleSearchButtonClick();
                    }
                  }}
                />
                <div style={{ display: 'flex', position: 'absolute', right: 0, marginRight: 16 }}>
                  {searchTerm ? (
                    <Close sx={{ color: '#707c8a' }} onClick={handleClearButton} />
                  ) : null}
                  <Divider flexItem className="mx-3" orientation="vertical" />
                  <Search sx={{ color: '#707c8a' }} onClick={handleSearchButtonClick} />
                </div>
              </div>
            </div>
          </Box>
        </div>

        <div className="d-flex justify-content-center">
          <Box component={TableContainer}>
            <div className="tablePaper">
              <table className="table m-0 table-hover w-100 border rounded table-hover-pointer">
                <thead className="tbl-header">
                  <tr className="sticky-header" role="row" style={{ top: '-1px' }}>
                    <th style={{ textAlign: 'center' }}>ID</th>
                    <th>Unit Info</th>
                    <th>Booking Date</th>
                    <th>Booked By</th>
                    <th>Client Name</th>
                    <th>Client Phone</th>
                    <th>Evaluated By</th>
                    <th>Status</th>
                  </tr>
                </thead>
                <tbody>
                  {searchApproval?.length ? (
                    searchApproval
                      ?.slice(page * rowsPerPage, (page + 1) * rowsPerPage)
                      ?.map(approvalListRow => {
                        const {
                          bookingid,
                          unitid,
                          unit_info,
                          unit_reserved_date,
                          bookedby,
                          evaluated_by,
                          status,
                          tower_id,
                          floor_id,
                          first_name,
                          last_name,
                          phone,
                        } = approvalListRow || {};
                        return (
                          <tr
                            key={`${unitid}-${bookingid}`}
                            role="row"
                            onClick={() => {
                              navtoDetail(unitid, bookingid, tower_id, floor_id);
                            }}
                          >
                            <td align="center" style={{ fontWeight: 'bold' }}>
                              {bookingid || '--'}
                            </td>
                            <td>{unit_info || '--'}</td>
                            <td>{unit_reserved_date || '--'}</td>
                            <td>{bookedby || '--'}</td>
                            <td>
                              {first_name || '--'} {last_name || '--'}
                            </td>
                            <td>{phone || '--'}</td>
                            <td>{evaluated_by.trim() === '' ? '--' : evaluated_by}</td>
                            <td>
                              <Typography
                                style={{
                                  ...styles.values,
                                  color: GLOBAL_STATUS[status]?.color,
                                }}
                              >
                                {GLOBAL_STATUS[status]?.label}
                              </Typography>
                            </td>
                          </tr>
                        );
                      })
                  ) : (
                    <tr>
                      {' '}
                      <td className="text-center" colSpan={8}>
                        No Data Available
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
            <div className="d-flex justify-content-between align-items-center mx-3 mt-1">
              <div className="d-flex align-items-center">
                <Typography
                  style={{ color: '#6c757d', fontWeight: 500, fontSize: 16 }}
                  variant="body2"
                >
                  Showing
                </Typography>
                <Typography
                  style={{
                    color: '#6c757d',
                    margin: '0 5px',
                    fontWeight: 'bold',
                    fontSize: '1.3rem',
                  }}
                  variant="body1"
                >
                  <span>{`${page * rowsPerPage + 1}-${Math.min(
                    (page + 1) * rowsPerPage,
                    searchApproval?.length || 0,
                  )} of ${searchApproval?.length || 0}`}</span>
                </Typography>
                <Typography
                  style={{ color: '#6c757d', fontWeight: 500, fontSize: 16 }}
                  variant="body2"
                >
                  results
                </Typography>
              </div>
              <div className="pagination-select">
                <select
                  className="form-control"
                  style={{ width: '100px' }}
                  value={page + 1}
                  onChange={event => handleChangePage(event)}
                >
                  {Array?.from(
                    { length: Math?.ceil(searchApproval?.length / rowsPerPage) },
                    (_, i) => i + 1,
                  ).map(pageNumber => (
                    <option key={pageNumber} value={pageNumber}>
                      {pageNumber}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </Box>
        </div>

        <ApprovalFilters
          open={open}
          processedBookingApprovals={processedBookingApprovals}
          toggleDrawer={toggleDrawer}
        />
      </Box>
    </Box>
  );
};

const BookingApproval = () => {
  const dispatch = useAppDispatch();
  const [modulePermission, setModulePermission] = useState();

  // url params
  // const project_id = searchParams.get('project_id');
  // const token = searchParams.get('token');
  // const user_id = searchParams.get('user_id');
  const token = Cookies.get('_hjtokenUser_2492391');
  const project_id = Cookies.get('_hjproject_idUser_2492391');
  const user_id = Cookies.get('_hjuseridUser_2492391');
  const pid = Cookies.get('_hjpidUser_2492391');
  // const project_id = '260';
  // const user_id = '45';
  // const pid =
  //   'eyJpdiI6Ik5sNXlyYUJpaWZMN2IzWHg2V1VhUUE9PSIsInZhbHVlIjoiRGx6eVhzQWRudHZxamRtXC96bHNXZlE9PSIsIm1hYyI6IjhiMDQzOTNmZDZhYjRjMDhhNmExODZlNDc4Yzg4ZDU1NDgwY2FkZjYwYTQ1ZjM1N2I2YTI4MDlkYTcyYzIzNGUifQ%3D%3D';

  const { bookingApprovalList, loading, booking_approval_filter } = useAppSelector(s => s.sales);
  const { loading: projectLoading, modulesData } = useAppSelector(s => s.project);

  useEffect(() => {
    dispatch(
      getProjectPermissions({
        project_id,
      }),
    );
    dispatch(
      getProjectModules({
        project_id,
      }),
    );
    dispatch(
      getBookingApprovalList({
        project_id,
      }),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setModulePermission(getPermissions('Booking Approval'));
  }, [modulesData]);

  return (
    <>
      <Loader loading={loading && projectLoading} />
      <BookingApprovalTable
        approvalFilter={booking_approval_filter}
        bookingApprovalList={bookingApprovalList}
        modulePermission={modulePermission}
        pid={pid}
        project_id={project_id}
        token={token}
        user_id={user_id}
      />
    </>
  );
};

export default BookingApproval;

const styles = {
  navigationButton: {
    backgroundColor: '#e5eafa',
    padding: '2px 5px',
    borderRadius: '5px',
    marginRight: '5px',
  },
  searchButton: {
    backgroundColor: '#e5eafa',
    marginLeft: '20px',
    padding: '15px 10px',
    borderRadius: '8px',
    transition: 'box-shadow 0.3s',
    '&:hover': {
      boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.3)',
      backgroundColor: '#e5eafa',
    },
  },
  values: {
    color: '#23394F',
    fontWeight: '500',
    padding: 5,
  },
};
