import axios from 'axios';
import Cookies from 'js-cookie';
import { BASE_API_URL } from 'utils/constant';

export const instance = axios.create({ baseURL: BASE_API_URL });

export const config = ({ multipart = true } = {}) => {
  const token = Cookies.get('_hjtokenUser_2492391');

  // const token ='eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJodHRwOlwvXC8xMjcuMC4wLjE6ODAwMFwvbmV3TG9naW4iLCJpYXQiOjE3MzQ1Nzk3OTksImV4cCI6MTczNDc1MjU5OSwibmJmIjoxNzM0NTc5Nzk5LCJqdGkiOiJEOWhhWjJ0QzZVRWZHSm9xIiwic3ViIjoxNTQsInBydiI6Ijg3ZTBhZjFlZjlmZDE1ODEyZmRlYzk3MTUzYTE0ZTBiMDQ3NTQ2YWEifQ.f-70QMw3Jx1xgePq0DjP_OAHXAaB6FIiEzNzRIO_yhg'
  const headers = {
    'Content-Type': 'application/json',
    Accept: 'application/json',
    Authorization: `Bearer ${token}`,
    'Access-Control-Allow-Origin': true,
  };

  if (multipart) {
    headers['Content-Type'] = 'multipart/form-data';
  }

  return { headers };
};

// Add a response interceptor
instance.interceptors.response.use(
  response => {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    if (response.data.status) {
      return response;
    }
    return Promise.reject(response);
  },
  async error => {
    const { message } = error?.response?.data || {};

    if (message?.includes('Unauthenticated')) {
      const originalRequest = error.config;
      console.log(originalRequest);

      try {
        const retryOriginalRequest = new Promise(resolve => {
          console.log('error', resolve);
        });

        return retryOriginalRequest;
      } catch (err) {
        return null;
      }
    }

    return Promise.reject(error);
  },
);
