import { styled, Typography } from '@mui/material';
import { useSyncedFields } from 'hooks/useDiscountCalculator';
import { useEffect } from 'react';
const Label = styled(Typography)`
  color: #53575b;
  font-weight: 400;
  font-size: 18px;
`;
const Value = styled(Typography)`
  font-size: 24px;
  font-weight: 500;
  color: #041d36;
`;
const GovtTaxes = props => {
  const { formikProps, handleTotalOtherCharge } = props;
  const { values, setFieldValue } = formikProps;

  useEffect(() => {
    const subTotal = Number(values.basic_rate_basic_amount) + parseFloat(handleTotalOtherCharge());

    // Recalculate GST amount
    const gstAmount = (subTotal * (values.gst_per / 100)).toFixed(2);
    setFieldValue('gst_amt', gstAmount);

    // Recalculate Stamp Duty amount
    const stampDutyAmount = (subTotal * (values.stampduty_per / 100)).toFixed(2);
    setFieldValue('stampduty_amount', stampDutyAmount);

    // Recalculate Registration amount
    const registrationAmount = (subTotal * (values.reg_per / 100)).toFixed(2);
    setFieldValue('reg_amount', registrationAmount);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.basic_rate_basic_amount, handleTotalOtherCharge]);

  // discount calculations hook
  const gstSyncedFields = useSyncedFields(
    Number(values.basic_rate_basic_amount) + parseFloat(handleTotalOtherCharge()),
    'gst_amt',
    'gst_per',
    setFieldValue,
  );

  const stampDutySyncedFields = useSyncedFields(
    Number(values.basic_rate_basic_amount) + parseFloat(handleTotalOtherCharge()),
    'stampduty_amount',
    'stampduty_per',
    setFieldValue,
  );

  const registrationSyncedFields = useSyncedFields(
    Number(values.basic_rate_basic_amount) + parseFloat(handleTotalOtherCharge()),
    'reg_amount',
    'reg_per',
    setFieldValue,
  );
  const gstAmt = Number(values.gst_amt) || 0;
  const stampDutyAmt = Number(values.stampduty_amount) || 0;
  const regAmt = Number(values.reg_amount) || 0;

  return (
    <div className="booking-form-box shwan-form mt-4">
      <div className="booking-form-col-12">
        <h5>Goverment Taxes</h5>
        <div className="form-row">
          <div className="form-group col-md-12">
            <div
              className="total-background"
              style={{
                display: 'flex',
                gap: '8rem',
                alignItems: 'center',
              }}
            >
              <Label>Sub Total Amount </Label>
              <Value>
                :
                <strong style={{ marginLeft: 10 }}>
                  {values.calculation_method
                    ? (
                        Number(values.basic_rate_basic_amount) +
                        parseFloat(handleTotalOtherCharge())
                      ).toFixed(2)
                    : '0.00'}
                </strong>
              </Value>
            </div>
          </div>
        </div>
        <div className="form-row">
          <div className="form-group col-2 form-col-gap">
            <label>GST</label>
          </div>
          <div className="form-group col-1  pr-4">
            <input
              className="form-control"
              name="gst_per"
              type="number"
              value={values.gst_per}
              onChange={gstSyncedFields.onChangePercent}
              // onInput={handleNumberInput}
            />
          </div>
          <div className="form-group col-3">
            <input
              className="form-control"
              name="gst_amt"
              type="number"
              value={values.gst_amt}
              onChange={gstSyncedFields.onChangeAmount}
              // onInput={handleNumberInput}
            />
          </div>
        </div>
        <div className="form-row">
          <div className="form-group col-2 form-col-gap">
            <label>Stamp Duty</label>
          </div>
          <div className="form-group col-1  pr-4">
            <input
              className="form-control"
              name="stampduty_per"
              type="number"
              value={values.stampduty_per}
              onChange={stampDutySyncedFields.onChangePercent}
              // onInput={handleNumberInput}
            />
          </div>
          <div className="form-group col-3">
            <input
              className="form-control"
              name="stampduty_amount"
              type="number"
              value={values.stampduty_amount}
              onChange={stampDutySyncedFields.onChangeAmount}
              // onInput={handleNumberInput}
            />
          </div>
        </div>
        <div className="form-row">
          <div className="form-group col-2 form-col-gap">
            <label>Registration</label>
          </div>
          <div className="form-group col-1  pr-4">
            <input
              className="form-control"
              name="reg_per"
              type="number"
              value={values.reg_per}
              onChange={registrationSyncedFields.onChangePercent}
              // onInput={handleNumberInput}
            />
          </div>
          <div className="form-group col-3">
            <input
              className="form-control"
              name="reg_amount"
              type="number"
              value={values.reg_amount}
              onChange={registrationSyncedFields.onChangeAmount}
              // onInput={handleNumberInput}
            />
          </div>
        </div>
        <div className="form-row">
          <div className="col-md-12">
            <div
              className="total-background"
              style={{
                display: 'flex',
                gap: '11.5rem',
                alignItems: 'center',
              }}
            >
              <Label>Taxes total</Label>
              <Value>
                :<span style={{ fontSize: '1.3rem' }}> ₹ </span>
                <strong>{(gstAmt + stampDutyAmt + regAmt).toFixed(2)}</strong>
              </Value>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GovtTaxes;
